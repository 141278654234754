<template>
    <v-container fluid class="pa-0">
        <!-- <slot name="submenu" /> -->

        <HubPageAuditView :page-id="pageId" />
    </v-container>
</template>

<script>
import HubPageAuditView from '../components/HubPageAuditView.vue'

export default {
    components: {
		HubPageAuditView,
	},
	data () {
		return {
			pageId: undefined,
		}
	},
	mounted () {
		if (this.$route.params.pageId) {
			this.pageId = Number(this.$route.params.pageId)
		}
	},
	watch: {
		'$route.params.pageId' (newV) {
            if (newV) {
                this.pageId = Number(newV)
            }
        },
	},
}
</script>
