<template>
	<v-data-table 
		:items="items"
		:headers="headers"
		:search="search"
		:page.sync="mOptions.page"
		:items-per-page.sync="mOptions.itemsPerPage"
		:sort-by.sync="mOptions.sortBy"
		:sort-desc.sync="mOptions.sortDesc"
		:loading="loading"
		:item-key="itemUniqueKey"
		:footer-props="{ itemsPerPageOptions: [10,25,50,100] }"
		:mobile-breakpoint="0"
		class="elevation-0 hub-pages-data-table"
		:class="computedClass"
		@click:row="viewPage"
	>
		<template #item.updated_at="{ item }">
			<span class="nowrap">{{ formatDateTime(item.updated_at) }}</span>
		</template>
		<template #item._actions="{ item }">
			<span class="nowrap">
				<v-btn text small color="#0083EB" :disabled="loading" @click.stop="viewPage(item)">
					<v-icon small>fas fa-eye</v-icon>
				</v-btn>
			</span>
		</template>
	</v-data-table>
</template>

<script>
import { defaultAuditOptions } from '../config/index'

const headers = [{
		text: 'Date',
		value: 'updated_at',
	},
	{
		text: 'Editor',
		value: 'change_made_by',
	},
	{
		text: '',
		value: '_actions',
		sortable: false,
		class: 'table-actions',
		width: 40,
	},
]

export default {
    props: {
		items: { type: Array, default: () => [] },
		search: { type: String, default: null },
		options: { type: Object, default: () => ({ ...defaultAuditOptions }) },
		loading: { type: Boolean, default: false },
	},
    data () {
        return {
			headers,
			itemUniqueKey: 'id',
        }
    },
    computed: {
		mOptions: {
			get () { return this.options },
			set (v) { this.$emit('update-options', v) },
		},
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		computedClass () {
			return [
				this.isMobileView && 'hub-pages-data-table--mobile'
			]
		},
    },
    methods: {
		viewPage (pageAuditItem) {
			if (this.loading) { return }
			this.$emit('view-page', pageAuditItem)
		},
    },
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

.hub-pages-data-table {
	&.hub-pages-data-table--mobile {
		::v-deep {	
			.v-data-footer {
				.v-data-footer__select {
					margin-right: 2rem;
					font-size: 0;

					.v-select {
						margin-left: 0;
					}
				}

				.v-data-footer__pagination {
					margin: 0 2rem 0 0;
				}

				.v-btn {
					margin: 0;
				}
			}
		}
	}

	::v-deep {
		thead tr th {
			line-height: 1.2;
			height: 36px;
		}
	}
}
</style>
